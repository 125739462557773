import React from "react"
import ColorshelfPage from "../../components/colorlist/colorshelfpage"

const colorlist = require('../../content/custom/colorlist/shelf5.json');


const ColorlibraryShelf5Page = () => (
	<ColorshelfPage
			activePath={"colorlibrary/shelf5"}
			colorList={colorlist}
		/>
)

export default ColorlibraryShelf5Page
